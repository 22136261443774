<template>
  <div class="innovation">
    <a href="javascript:void(0);" class="scrollT" ref="innovation"></a>
    <div
      class="sub-banner"
      v-if="bannerList.length > 0"
      :style="{ backgroundImage: 'url(' + bannerList[0].pic + ')' }"
    >
      <div class="container">
        <div class="main">
          <h2 class="wow fadeInDown">
            Promote the <br />development of green <br />energy industry
          </h2>
        </div>
      </div>
    </div>
    <div class="inside-nav wow fadeInDown">
      <div class="container">
        <div class="list">
          <a href="javascript:void(0);" @click="goAnchor(0)"
            >01 <br />Technology</a
          >
          <a href="javascript:void(0);" @click="goAnchor(1)"
            >02 <br />Quality</a
          >
          <a href="javascript:void(0);" @click="goAnchor(2)"
            >03 <br />Manufacture</a
          >
        </div>
      </div>
    </div>
    <div class="mainbody">
      <div class="technology-box cell-box">
        <a href="javascript:void(0);" class="anchor" ref="tech"></a>
        <div class="container">
          <div class="top">
            <h3 class="wow fadeInLeft">Technology</h3>
            <div class="right">
              <div class="info wow fadeInRight">
                We focus on innovation of technology, promoting the development
                of the green energy industry.
              </div>
              <div class="nav wow fadeInRight">
                <li
                  v-for="(t, index) in techList"
                  :class="tIndex === index ? 'active' : ''"
                  :key="t.id"
                  @click="changeTech(index)"
                >
                  {{ t.name }}
                </li>
              </div>
            </div>
          </div>
          <div class="tabs wow fadeInUp">
            <div
              class="swiper-container swiper-tech"
              v-show="techSubList.length > 0"
            >
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="s in techSubList" :key="s.id">
                  <div class="main">
                    <div class="name">{{ s.title }}</div>
                    <div class="txt" v-html="s.detail"></div>
                  </div>
                  <div
                    class="imgBg"
                    :style="{ backgroundImage: 'url(' + s.pic + ')' }"
                  ></div>
                </div>
              </div>
              <div class="swiper-button-next" v-show="techSubList.length > 1">
                <i></i>
              </div>
              <div class="swiper-button-prev" v-show="techSubList.length > 1">
                <i></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="qualtily-box cell-box">
        <a href="javascript:void(0);" class="anchor" ref="qual"></a>
        <div class="container">
          <h3 class="wow fadeInLeft">Quality</h3>
          <div class="right" v-if="qualList.length > 0">
            <div class="nav">
              <div class="item" :class="qualIndex == index ? 'active':''" v-for="(item, index) in qualList" 
              :key="item.id" @click="qualIndex = index">
                {{ item.title }}
              </div>
            </div>
            <div class="info wow fadeInRight" v-html="qualList[qualIndex].detail"></div>
            <div class="swiper-container swiper-qualtily">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(item, index) in qualList[qualIndex].picList" :key="index">
                  <img :src="item" alt="" />
                </div>
                <div class="swiper-button-next" v-show="qualList[qualIndex].picList.length > 1">
                  <i></i>
                </div>
                <div class="swiper-button-prev" v-show="qualList[qualIndex].picList.length > 1">
                  <i></i>
                </div>
              </div>
            </div>
            <!-- <div class="name wow fadeInRight" data-wow-delay="0.2s">
              {{ qualInfo.title }}
            </div> -->
            <!-- <div class="pic wow fadeInUp" data-wow-delay="0.3s">
              <img :src="qualInfo.pic" alt="" />
            </div> -->
          </div>
        </div>
      </div>
      <div class="manu-box cell-box">
        <a href="javascript:void(0);" class="anchor" ref="manu"></a>
        <div class="container">
          <h3 class="wow fadeInLeft">Manufacture</h3>
          <div class="right">
            <ul class="nav wow fadeInRight">
              <li
                :class="mIndex === index ? 'active' : ''"
                v-for="(m, index) in manuList"
                :key="m.id"
                @click="changeManu(index)"
              >
                {{ m.title }}
              </li>
            </ul>
            <div class="tabs wow fadeInUp">
              <div class="item">
                <div class="pic" @click="showVideopop(manuInfo.video)">
                  <i class="player" v-if="manuInfo.video !== ''"></i>
                  <img :src="manuInfo.pic" alt="" />
                </div>
                <div class="content" v-html="manuInfo.detail"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="media-pop" v-show="showVideo">
      <div class="media-pop-main">
        <a href="javascript:void(0);" class="close" @click="closeVideo"></a>
        <video v-if="videoSrc !== ''" :src="videoSrc" controls autoplay></video>
      </div>
    </div>
  </div>
</template>

<script>
import { WOW } from 'wowjs'
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'
import { getInnovationInfo, getBannerList } from '../../request/api'
export default {
  name: 'Innovation',
  metaInfo:{
      title:"DMEGC Solar-innovation",
      meta: [{                 // set meta
          name: 'keyWords',
          content: 'DMEGC Solar'
      },{
          name:'description',
          content:'DMEGC Solar'
      }]
  },
  data () {
    return {
      bannerList: [],
      techList: [],
      tIndex: 0,
      techSubList: [],
      qualList: [],
      qualIndex: 0,
      manuList: [],
      mIndex: 0,
      manuInfo: {},
      showVideo: false,
      videoSrc: ''
    }
  },
  mounted () {

    this.$bus.$on('headNav',(data) => {
      this.goAnchor(data)
    })

    new Swiper('.swiper-tech', {
      slidesPerView: 1,
      spaceBetween: 10,
      observer: true,
      navigation: {
        nextEl: '.swiper-tech .swiper-button-next',
        prevEl: '.swiper-tech .swiper-button-prev',
      },
    })

    getBannerList({ cate: 'innovate' }).then(res => {

      this.bannerList = res.data
    })

    getInnovationInfo().then(res => {

      this.techList = res.data.subCateList
      this.techSubList = res.data.subCateList[this.tIndex].teachList
      this.qualList = res.data.qualityList
      this.manuList = res.data.manuList
      this.manuInfo = res.data.manuList[this.mIndex]
      this.$nextTick(() => {

        const wow = new WOW({
          animateClass: 'animated',
          offset: 100
        })

        new Swiper('.swiper-qualtily', {
          slidesPerView: 1,
          spaceBetween: 10,
          observer: true,
          navigation: {
            nextEl: '.swiper-qualtily .swiper-button-next',
            prevEl: '.swiper-qualtily .swiper-button-prev',
          },
        })

        wow.init()
        if(this.$route.fullPath.indexOf('pos') !== -1) {

          let num = parseInt(this.$route.fullPath.split('pos=')[1])
          this.goAnchor(num)
        } else {

          this.$refs.innovation.scrollIntoView()
        }
      })
    })
  },
  beforeDestroy() {
    this.$bus.$off('headNav')
  },
  methods: {
    goAnchor (num) {

      if (num === 0) {

        this.$refs.tech.scrollIntoView({ behavior: "smooth" })
      } else if (num === 1) {

        this.$refs.qual.scrollIntoView({ behavior: "smooth" })
      } else if (num === 2) {

        this.$refs.manu.scrollIntoView({ behavior: "smooth" })
      }
    },
    changeTech (num) {

      console.log('changeTech', num)
      this.tIndex = num
      this.techSubList = this.techList[num].teachList
    },
    changeManu (num) {

      this.mIndex = num
      this.manuInfo = this.manuList[num]
    },
    showVideopop (src) {

      if (src !== '') {

        this.showVideo = true
        this.videoSrc = src
      }
    },
    closeVideo () {

      this.showVideo = false
      this.videoSrc = ''
    }
  }

}
</script>

<style lang="scss" scoped>
.innovation {
  position: relative;
  .technology-box {
    background: #f2f4f8;
    padding: 4.1667vw 0;
    .top {
      display: flex;
      justify-content: space-between;
    }
    .info {
      font-size: 1.6146vw;
      line-height: 2.0313vw;
      color: rgba(0, 0, 0, 0.6);
      width: 40.1042vw;
    }
    .nav {
      display: flex;
      margin-top: 5vw;
      li {
        margin-right: 3.8542vw;
        font-size: 0.9896vw;
        line-height: 2.4479vw;
        color: rgba(0, 0, 0, 0.6);
        cursor: pointer;
      }
      .active {
        border-bottom: 2px solid #e60011;
      }
    }
    .tabs {
      margin-top: 3.8542vw;
    }
    .swiper-tech {
      height: 29.1667vw;
      padding-bottom: 4.1667vw;
      .swiper-slide {
        display: flex;
      }
      .main {
        width: 31.25vw;
        background: #fff;
        box-sizing: border-box;
        padding: 5.2083vw 5.2083vw 0;
      }
      .name {
        font-size: 1.5625vw;
        line-height: 1.9792vw;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.8);
      }
      .txt {
        margin-top: 1.9792vw;
        font-size: 1.0417vw;
        line-height: 1.5625vw;
        color: rgba(0, 0, 0, 0.8);
        font-weight: 300;
      }
      .imgBg {
        width: 50vw;
        height: 29.1667vw;
      }
      .swiper-button-next,
      .swiper-button-prev {
        left: 0;
        top: auto;
        margin: 0;
        bottom: 0;
        width: 2.6042vw;
        height: 2.6042vw;
        background: #232323;
        &::after {
          display: none;
        }
        i {
          display: block;
          width: 1.25vw;
          height: 1.25vw;
          background: url("../../assets/images/arrowR.png") no-repeat;
          background-size: cover;
          transform: rotate(180deg);
        }
        &:hover {
          background: #e60011;
        }
      }
      .swiper-button-next {
        margin-left: calc(2.6042vw + 2px);
        i {
          transform: rotate(0deg);
        }
      }
    }
  }
  .qualtily-box {
    padding: 4.1667vw 0 4.8958vw;
    .container {
      display: flex;
      justify-content: space-between;
    }
    .nav{
      display: flex;
      .item{
        margin-right: 3.8542vw;
        font-size: 0.9896vw;
        line-height: 1.5;
        color: rgba(0, 0, 0, 0.6);
        padding-bottom: 0.5208vw;
        position: relative;
        cursor: pointer;
      }
      .active{
        border-bottom: 2px solid #e60011;
      }
    }
    .info {
      font-size: 1.25vw;
      line-height: 1.5;
      color: #000;
      margin-top: 2.8125vw;
      /deep/p {
        padding-left: 1.1458vw;
        font-weight: 300;
        position: relative;
        font-size: 1.25vw;
        line-height: 1.5;
        margin-bottom: 0.8333vw;
        &::before {
          content: "";
          width: 0.3125vw;
          height: 0.3125vw;
          background: #e60011;
          border-radius: 50%;
          position: absolute;
          left: 0;
          top: 1.1458vw;
        }
      }
    }
    .name {
      font-size: 1.5625vw;
      line-height: 2.6042vw;
      color: rgba(0, 0, 0, 0.8);
      font-weight: bold;
      margin-top: 2.0833vw;
    }
    .swiper-qualtily{
      margin: 2.3438vw 0 0;
      width: 45vw;
      img{
        display: block;
        width: 100%;
      }
      .swiper-button-next,
      .swiper-button-prev {
        left: 0;
        top: auto;
        margin: 0;
        bottom: 0;
        width: 2.6042vw;
        height: 2.6042vw;
        background: #232323;
        &::after {
          display: none;
        }
        i {
          display: block;
          width: 1.25vw;
          height: 1.25vw;
          background: url("../../assets/images/arrowR.png") no-repeat;
          background-size: cover;
          transform: rotate(180deg);
        }
        &:hover {
          background: #e60011;
        }
      }
      .swiper-button-next {
        margin-left: calc(2.6042vw + 2px);
        i {
          transform: rotate(0deg);
        }
      }
    }
  }
  .manu-box {
    padding: 4.1667vw 0;
    background: #f2f4f8;
    .container {
      display: flex;
      justify-content: space-between;
    }
    .nav {
      display: flex;
      li {
        margin-right: 4.4271vw;
        font-size: 0.9896vw;
        line-height: 2.1875vw;
        color: rgba(0, 0, 0, 0.6);
        cursor: pointer;
      }
      .active {
        border-bottom: 2px solid #e60011;
      }
    }
    .tabs {
      margin-top: 2.6042vw;
      .pic {
        position: relative;
      }
      .player {
        display: block;
        cursor: pointer;
        width: 3.6458vw;
        height: 3.6458vw;
        background: url("../../assets/images/player.png") no-repeat;
        background-size: cover;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      .content {
        margin-top: 1.9792vw;
        font-size: 1.0417vw;
        line-height: 1.4583vw;
        color: rgba(0, 0, 0, 0.8);
        font-weight: 300;
      }
    }
  }
  .media-pop {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    .media-pop-main {
      width: 960px;
      height: 540px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: #fff;
      video {
        display: block;
        width: 100%;
        height: 540px;
      }
      .close {
        position: absolute;
        right: -30px;
        top: -30px;
        width: 24px;
        height: 24px;
        background: url("../../assets/images/close.png") no-repeat;
        background-size: cover;
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .innovation {
    .technology-box {
      padding: 0.8rem 0;
      .top {
        display: block;
      }
      .info {
        font-size: 0.26rem;
        line-height: 0.44rem;
        width: 100%;
      }
      .nav {
        margin-top: 0.4rem;
        li {
          font-size: 0.28rem;
          line-height: 0.6rem;
          margin-right: 0.4rem;
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .tabs {
        margin-top: 0.6rem;
      }
      .swiper-tech {
        padding-bottom: 1.2rem;
        height: auto;
        .swiper-slide {
          flex-direction: column-reverse;
        }
        .imgBg {
          width: 100%;
          height: 4.02rem;
        }
        .main {
          width: 100%;
          height: auto;
          padding: 0.3rem;
        }
        .name {
          font-size: 0.28rem;
          line-height: 0.46rem;
        }
        .txt {
          font-size: 0.26rem;
          line-height: 0.44rem;
          margin-top: 0.2rem;
        }
        .swiper-button-next,
        .swiper-button-prev {
          width: 0.6rem;
          height: 0.6rem;
          i {
            width: 0.3rem;
            height: 0.3rem;
          }
        }
        .swiper-button-next {
          margin-left: calc(0.6rem + 4px);
        }
      }
    }
    .qualtily-box {
      padding: 0.8rem 0;
      .container {
        display: block;
      }
      .nav{
        .item{
          font-size: 0.3rem;
          margin-right: 0.8rem;
          line-height: 0.8rem;
          padding-bottom: .2rem;
        }
      }
      .info {
        font-size: 0.26rem;
        line-height: 0.44rem;
        margin-top: .6rem;
        /deep/ p {
          padding-left: 0.2rem;
          font-size: .26rem;
          line-height: .44rem;
          margin-bottom: .3rem;
          &::before {
            width: 0.1rem;
            height: 0.1rem;
            top: 0.17rem;
          }
        }
      }
      .pic {
        margin-top: 0.5rem;
        width: 100%;
      }
      .swiper-qualtily{
        width: 100%;
        margin-top: .5rem;
        .swiper-button-next,
        .swiper-button-prev {
          width: 0.6rem;
          height: 0.6rem;
          i {
            width: 0.3rem;
            height: 0.3rem;
          }
        }
        .swiper-button-next {
          margin-left: calc(0.6rem + 4px);
        }
      }
    }
    .manu-box {
      padding: 0.8rem 0;
      .container {
        display: block;
      }
      .nav {
        li {
          font-size: 0.3rem;
          margin-right: 0.8rem;
          line-height: 0.8rem;
        }
      }
      .tabs {
        margin-top: 0.6rem;
        .content {
          font-size: 0.26rem;
          line-height: 0.44rem;
        }
      }
    }
  }
}
</style>